import { basePath } from 'src/env';

export const RoutePaths = {
    index: `${basePath}/`,
    tasks: `${basePath}/tasks/:taskId?`,
    process: `${basePath}/process/:processKey`,
    loanApplications: `${basePath}/loan-applications`,
    loanApplicationDetails: `${basePath}/loan-applications/:loanApplicationId`,
    loanApplicationCreate: `${basePath}/loan-applications/create`,
    loans: `${basePath}/loans`,
    loanDetails: `${basePath}/loans/:loanId`,
    loanCreate: `${basePath}/loans/create`,
    products: `${basePath}/products`,
    productDetails: `${basePath}/products/:productId`,
    productCreate: `${basePath}/products/create`,
    campaigns: `${basePath}/campaigns`,
    campaignsDetails: `${basePath}/campaigns/:campaignId`,
    campaignsCreate: `${basePath}/campaigns/create`,
    transactions: `${basePath}/transactions`,
    transactionDetails: `${basePath}/transactions/:transactionId`,
    operations: `${basePath}/loans/:loanId/operations`,
    operationDetails: `${basePath}/loans/:loanId/operations/:operationId`,
    borrowers: `${basePath}/borrowers`,
    borrowerDetails: `${basePath}/borrowers/:productId`,
    collections: `${basePath}/collections`,
    collectionsDetail: `${basePath}/collections/:collectionId`,
    agents: `${basePath}/agents`,
    merchantDetails: `${basePath}/agents/:merchantId`,
    agentsCreate: `${basePath}/agents/create`,
    notAccessPage: `${basePath}/notAccessPage`,
    profile: (authServerUrl: string, authRealm: string) =>
        `${authServerUrl}/realms/${authRealm}/account`,
    formioSandBox: `${basePath}/formio-sandbox`,
    leads: `${basePath}/leads`,
    leadsDetails: `${basePath}/leads/:leadId`,
    leadsCreate: `${basePath}/leads/create`,
    viewProfile: `${basePath}/profile`,
    viewProfileDetails: `${basePath}/profile/:me`,
    agentLoanApplication: `${basePath}/agent-loan-applications`,
    agentLoanApplicationDetails: `${basePath}/agent-loan-applications/:agentLoanApplicationId`,
    agentLoanApplicationCreate: `${basePath}/agent-loan-applications/create`,
    agentLoans: `${basePath}/agent-loans`,
    agentLoanDetails: `${basePath}/agent-loans/:loanId`,
    agentLoanCreate: `${basePath}/agent-loans/create`,
    operationsAgent: `${basePath}/agent-loans/:loanId/operations`,
    operationDetailsAgent: `${basePath}/agent-loans/:loanId/operations/:operationId`,
    requestsLog: `${basePath}/requests-log`,
    requestsLogDetails: `${basePath}/requests-log/:logId`,
};
