import { ZoomInOutlined, ZoomOutOutlined } from '@ant-design/icons';
import { DocRenderer, DocRendererProps } from '@cyntler/react-doc-viewer';
import { Button } from 'antd';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { Spinner } from 'src/components/Spinner/Spinner';
import styled from 'styled-components';

pdfjs.GlobalWorkerOptions.workerSrc = '/pdfjs.2.12.313.worker.js';

export const Component: React.FC<DocRendererProps> = observer(
    ({
        // eslint-disable-next-line react/prop-types
        mainState: { currentDocument },
    }) => {
        const [totalPages, setCountPages] = useState(0);
        const [zoom, setZoom] = useState(1);

        const zoomIn = () => {
            setZoom(Math.min(zoom + 0.1, 1.5));
        };

        const zoomOut = () => {
            setZoom(Math.max(zoom - 0.1, 0.2));
        };

        const resetZoom = () => {
            setZoom(1);
        };

        if (!currentDocument) {
            return null;
        }

        return (
            <StyledDiv>
                <StyledPDFDocument
                    file={currentDocument.uri}
                    onLoadSuccess={(pdf) => {
                        setCountPages(pdf.numPages);
                    }}
                    loading={<StyledSpinner />}
                >
                    {Array.from({ length: totalPages }).map((_, index) => {
                        return (
                            <Page
                                scale={zoom}
                                pageNumber={index + 1}
                                key={index}
                                renderAnnotationLayer={false}
                            />
                        );
                    })}
                </StyledPDFDocument>
                {totalPages ? (
                    <Footer>
                        <StyledButton
                            onClick={() => {
                                zoomOut();
                            }}
                        >
                            <ZoomOutOutlined />
                        </StyledButton>
                        <StyledButton
                            onClick={() => {
                                resetZoom();
                            }}
                        >
                            <StyledSpan>100%</StyledSpan>
                        </StyledButton>
                        <StyledButton
                            onClick={() => {
                                zoomIn();
                            }}
                        >
                            <ZoomInOutlined />
                        </StyledButton>
                    </Footer>
                ) : null}
            </StyledDiv>
        );
    },
) as DocRenderer;

(Component as DocRenderer).fileTypes = ['pdf', 'application/pdf'];
(Component as DocRenderer).weight = 1;

export const PDFRenderer = Component as DocRenderer;

const StyledPDFDocument = styled(Document)`
    position: relative;
    width: fit-content;
    margin: 0 auto;

    canvas {
        transform: translateY(9px);
    }
`;

const StyledDiv = styled.div`
    height: 100%;
    width: 100%;
    position: relative;
    overflow: auto;
    margin: 0 auto;
    padding: 50px 0 10px;
    min-height: calc(100vh - 50px);
`;

const Footer = styled.div`
    position: sticky;
    bottom: 0;
    left: calc(50% - 50px);
    margin: 0 auto;
    width: fit-content;
    background: #1e1e1ed9;
`;

const StyledButton = styled(Button)`
    border-radius: 0;
    background: none;
    border: none;
    color: white;
    font-size: 22px;
    height: fit-content;
    line-height: 1;

    &:hover,
    &:active,
    &:focus {
        background: #31313199;
        color: white;
    }

    .anticon {
        vertical-align: 0;
    }
`;

const StyledSpan = styled.span`
    vertical-align: 2px;
    font-size: 14px;
`;

const StyledSpinner = styled(Spinner)`
    color: white !important;
`;

export const extractFirstPageAsImage = async (url: string) => {
    try {
        const pdfDoc = pdfjs.getDocument(url);

        const pdf = await pdfDoc.promise;
        const firstPage = await pdf.getPage(1);
        const viewport = firstPage.getViewport({ scale: 0.5 });
        const canvas = document.createElement('canvas');
        canvas.style.opacity = '0';
        canvas.style.position = 'fixed';
        canvas.style.zIndex = '-100';
        const context = canvas.getContext('2d');
        canvas.width = viewport.width;
        canvas.height = viewport.height;

        document.body.appendChild(canvas);

        const renderTask = await firstPage.render({
            canvasContext: context as any,
            viewport,
        });

        await renderTask.promise;

        const image = canvas.toDataURL('image/jpeg');

        canvas.remove();

        return image;
    } catch (error) {}
};
