import _ from 'lodash';
import { computed } from 'mobx';
import { TaskRepresentation } from 'src/generated-api-client';
import {
    bpmsResourcesManagerApi,
    taskManagerApi,
} from 'src/services/apiServices';
import { InitialFilters } from 'src/stores/AgentsRequestsLogStore/InitialFilters';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import { BasicStoreApi } from 'src/utils/mobx/BasicStore/BasicStore.types';
import { FilterCriteria } from 'src/utils/mobx/FilterCriteria';
import { Pager } from 'src/utils/mobx/Pager';
import { RequestHelper } from 'src/utils/RequestHelper';

export type AgentsFilter = {
    search?: string;
    taxId?: string;
};

export class AgentsRequestsLogStoreClass extends BasicStore<TaskRepresentation> {
    pager = new Pager();

    api: BasicStoreApi<TaskRepresentation> = {
        loadList: async () => {
            const resp = await RequestHelper.unwrapFromAxiosPromise(
                taskManagerApi.listAvailable(
                    this.pager.page,
                    this.pager.size,
                    InitialFilters.defaultSort,
                ),
            );

            this.pager.setTotal(resp.totalElements || 0);

            return resp.content || [];
        },
        loadItemForm: (formKey: string) => {
            return RequestHelper.unwrapFromAxiosPromise(
                bpmsResourcesManagerApi.getForm(formKey),
            );
        },
    };

    filterCriteria: FilterCriteria<AgentsFilter> = new FilterCriteria();

    @computed get hasFilter() {
        const { taxId, search } = this.filterCriteria.filter;

        return !_.isNil(taxId) || !_.isNil(search);
    }
}

export const AgentsRequestsLogStore = new AgentsRequestsLogStoreClass();
