import { generatePath } from 'react-router-dom';
import { RoutePaths } from 'src/core/router/RoutePaths';

type Links = {
    list: () => string;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    itemDetails: (id?: string | number) => string;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    itemEdit?: (id?: string | number) => string;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    process?: (id?: string | number) => string;

    create?: () => string;
};

export type EntityNames =
    | 'tasks'
    | 'applications'
    | 'loans'
    | 'products'
    | 'campaigns'
    | 'transactions'
    | 'borrowers'
    | 'collections'
    | 'agents'
    | 'leads'
    | 'agentLoanApplications'
    | 'agentLoans'
    | 'operations'
    | 'agentOperations'
    | 'requestsLog';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const EntityLinks: { [key in EntityNames]: Links } = {
    tasks: {
        list: () =>
            generatePath(RoutePaths.tasks, {
                taskId: undefined,
            }),
        itemDetails: (id) =>
            generatePath(RoutePaths.tasks, {
                taskId: id,
            }),
    },
    applications: {
        list: () => RoutePaths.loanApplications,
        itemDetails: (id) =>
            generatePath(RoutePaths.loanApplicationDetails, {
                loanApplicationId: id,
            }),
        create: () => RoutePaths.loanApplicationCreate,
    },
    agentLoanApplications: {
        list: () => RoutePaths.agentLoanApplication,
        itemDetails: (id) =>
            generatePath(RoutePaths.agentLoanApplicationDetails, {
                agentLoanApplicationId: id,
            }),
        create: () => RoutePaths.agentLoanApplicationCreate,
    },
    loans: {
        list: () => RoutePaths.loans,
        itemDetails: (id) =>
            generatePath(RoutePaths.loanDetails, {
                loanId: id,
            }),
        create: () => RoutePaths.loanCreate,
    },
    agentLoans: {
        list: () => RoutePaths.agentLoans,
        itemDetails: (id) =>
            generatePath(RoutePaths.agentLoanDetails, {
                loanId: id,
            }),
        create: () => RoutePaths.loanCreate,
    },
    products: {
        list: () => RoutePaths.products,
        itemDetails: (id) =>
            generatePath(RoutePaths.productDetails, {
                productId: id,
            }),
        create: () => RoutePaths.productCreate,
    },

    campaigns: {
        list: () => RoutePaths.campaigns,
        itemDetails: (id) =>
            generatePath(RoutePaths.campaignsDetails, {
                campaignId: id,
            }),
        create: () => RoutePaths.campaignsCreate,
    },

    borrowers: {
        list: () => RoutePaths.borrowers,
        itemDetails: (id) =>
            generatePath(RoutePaths.borrowerDetails, {
                productId: id,
            }),
    },

    transactions: {
        list: () => RoutePaths.transactions,
        itemDetails: (id) =>
            generatePath(RoutePaths.transactionDetails, {
                transactionId: id,
            }),
    },

    operations: {
        list: () => RoutePaths.operations,
        itemDetails: (id) =>
            generatePath(RoutePaths.operationDetails, {
                operationId: id,
            }),
    },
    agentOperations: {
        list: () => RoutePaths.operationsAgent,
        itemDetails: (id) =>
            generatePath(RoutePaths.operationDetailsAgent, {
                operationId: id,
            }),
    },
    collections: {
        list: () => RoutePaths.collections,
        itemDetails: (id) =>
            generatePath(RoutePaths.collectionsDetail, {
                collectionId: id,
            }),
    },
    agents: {
        list: () => RoutePaths.agents,
        itemDetails: (id) =>
            generatePath(RoutePaths.merchantDetails, {
                merchantId: id,
            }),
        create: () => RoutePaths.agentsCreate,
    },
    leads: {
        list: () => RoutePaths.leads,
        itemDetails: (id) =>
            generatePath(RoutePaths.leadsDetails, {
                leadId: id,
            }),
        create: () => RoutePaths.leadsCreate,
    },
    requestsLog: {
        list: () => RoutePaths.requestsLog,
        itemDetails: (id) =>
            generatePath(RoutePaths.requestsLogDetails, {
                logId: id,
            }),
    },
};
