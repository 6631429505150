import { Tooltip } from 'antd';
import { observer } from 'mobx-react-lite';
import { EllipsisContainer } from 'src/components/EllipsisContainer/EllipsisContainer';
import { EntityStatusInfo } from 'src/components/EntityStatusInfo/EntityStatusInfo';
import {
    MainContainer,
    MainEntityListItemText,
    MasterDetailsAsideItem,
    SecondaryContainer,
    SecondaryEntityListItemText,
} from 'src/components/MasterDetailsPage/MasterDetailsAsideItem';
import { EntityLinks } from 'src/core/router/EntityLinks';
import { getMaskedPhoneValue } from 'src/formio/utils/getDisabledValue';
import { AgentBrief } from 'src/generated-api-client';
import { AgentStatusInfoMap } from 'src/pages/AgentsPage/AgentStatusInfoMap';

type AgentsListItemProps = { item: AgentBrief };

export const AgentsListItem = observer(({ item }: AgentsListItemProps) => {
    return (
        <MasterDetailsAsideItem to={EntityLinks.agents.itemDetails(item.id)}>
            <MainContainer>
                <Tooltip title={item.legalName}>
                    <EllipsisContainer>
                        <MainEntityListItemText className="strong-text">{`${item.id} `}</MainEntityListItemText>
                        <MainEntityListItemText>
                            {item.legalName}
                        </MainEntityListItemText>
                    </EllipsisContainer>
                </Tooltip>
                <EntityStatusInfo
                    statusInfoMap={AgentStatusInfoMap}
                    status={getAgentStatus(item.active)}
                    entityName="agent"
                />
            </MainContainer>
            <SecondaryContainer>
                <Tooltip title={getMaskedPhoneValue(item.phone)}>
                    <EllipsisContainer>
                        <SecondaryEntityListItemText>
                            <span className="strong-text">
                                {getMaskedPhoneValue(item.phone)}
                            </span>
                        </SecondaryEntityListItemText>
                    </EllipsisContainer>
                </Tooltip>
                <Tooltip title={item.email}>
                    <EllipsisContainer>
                        <SecondaryEntityListItemText>
                            {item.email}
                        </SecondaryEntityListItemText>
                    </EllipsisContainer>
                </Tooltip>
            </SecondaryContainer>
        </MasterDetailsAsideItem>
    );
});

const getAgentStatus = (status: boolean) => {
    if (status) {
        return 'ACTIVE';
    }

    return 'INACTIVE';
};
