/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Env } from 'src/core/Environment';

export const useListenRoutesForSegment = () => {
    const history = useHistory();
    useEffect(() => {
        if (Env.isAgentPortal) {
            history.listen(() => {
                // @ts-ignore
                window?.analytics?.page({
                    title: 'Merchant portal',
                });
            });
        }
    }, []);
};
