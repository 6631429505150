import {
    convertMapToList,
    DEFAULT_STATUS_KEY,
    StatusInfo,
} from 'src/components/EntityStatusInfo/EntityStatusInfo';
import { CssColors } from 'src/styles/CssVariables';

export const AgentStatusInfoMap: Record<string, StatusInfo> = {
    ACTIVE: {
        title: 'Active',
        color: CssColors.mainGreen,
        borderColor: CssColors.mainGreen,
        backgroundColor: CssColors.mainGreen,
    },
    INACTIVE: {
        title: 'Inactive',
        color: CssColors.mainRed,
        borderColor: CssColors.mainRed,
        backgroundColor: CssColors.mainRed,
    },
    [DEFAULT_STATUS_KEY]: {
        title: '',
        color: CssColors.mainGray,
        borderColor: CssColors.mainGray,
        backgroundColor: CssColors.mainGray,
    },
};

export const getAgentStatuses = () => {
    return convertMapToList(AgentStatusInfoMap);
};
