// eslint-disable-next-line simple-import-sort/sort
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { FormioForm } from 'src/components/FormioForm/FormioForm';
import styled from 'styled-components';
import {
    Redirect,
    useLocation,
    useParams,
    useRouteMatch,
} from 'react-router-dom';
import { TaskViewViewModel } from 'src/pages/TasksPage/TaskViewViewModel';
import { BasePage, PageType } from 'src/components/BasePage';
import { Col, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import { TaskRepresentation } from 'src/generated-api-client';

type RequestsLogViewParams = {
    logId: string;
};

export const RequestsLogView = observer(
    ({ store }: { store: BasicStore<TaskRepresentation> }) => {
        const { logId } = useParams<RequestsLogViewParams>();
        const { path } = useRouteMatch();
        const { pathname } = useLocation();

        useEffect(() => {
            TaskViewViewModel.loadItem(logId);

            return () => {
                TaskViewViewModel.reset();
            };
        }, [logId]);

        const { isLoading } = TaskViewViewModel.loadingStatus;

        if (!pathname.match(/\d/) && store?.list.length > 0) {
            return <Redirect to={`${path}/${store?.list?.[0]?.id}`} />;
        }

        return (
            <StyledPage
                pageName="requests-log"
                isLoading={isLoading}
                type={PageType.FULL}
            >
                {logId ? (
                    <>
                        <TaskViewHeader />
                        <FormioForm
                            form={TaskViewViewModel.taskForm as any}
                            submission={TaskViewViewModel.taskVariables}
                            normalizeSubmission
                        />
                    </>
                ) : null}
            </StyledPage>
        );
    },
);

const TaskViewHeader = observer(() => {
    const { t } = useTranslation();

    if (!TaskViewViewModel.taskDefinition) {
        return null;
    }
    const { taskDefinition } = TaskViewViewModel;

    const hasL10nTitleKey = Boolean(taskDefinition.extensions?.l10nTitleKey);
    const title = hasL10nTitleKey
        ? t(`${taskDefinition.extensions?.l10nTitleKey}`, {
              ...taskDefinition?.taskInfo,
          })
        : taskDefinition.name;

    return (
        <StyledHeaderRow gutter={10} wrap={false} align="middle">
            <Col flex={1}>
                <StyledTitlte>{title}</StyledTitlte>
            </Col>
        </StyledHeaderRow>
    );
});

const StyledHeaderRow = styled(Row)`
    margin-bottom: 1.8rem;
`;

const StyledPage = styled(BasePage)`
    padding: 2rem 1.8rem;
    .ant-list {
        margin-left: 1.2rem;
    }
    .formio-component-submit1 {
        button {
            margin-left: 1.6rem;
        }
    }
`;

const StyledTitlte = styled(Typography.Title)`
    padding-left: 1.6rem;
    margin-bottom: 0 !important;
`;
