import {
    convertMapToList,
    DEFAULT_STATUS_KEY,
    StatusInfo,
} from 'src/components/EntityStatusInfo/EntityStatusInfo';
import { CssColors } from 'src/styles/CssVariables';

export const LoanStatusInfoMap: Record<string, StatusInfo> = {
    PENDING: {
        title: 'Pending',
        color: CssColors.mainGray,
        borderColor: CssColors.mainGray,
        backgroundColor: CssColors.mainGray,
    },
    ACTIVE: {
        title: 'Active',
        color: CssColors.mainGreen,
        borderColor: CssColors.mainGreen,
        backgroundColor: CssColors.mainGreen,
    },
    REPAID: {
        title: 'Repaid',
        color: CssColors.mainGray,
        borderColor: CssColors.mainGray,
        backgroundColor: CssColors.mainGray,
    },
    OVERDUE: {
        title: 'Overdue',
        color: CssColors.mainRed,
        borderColor: CssColors.mainRed,
        backgroundColor: CssColors.mainRed,
    },
    WRITTEN_OFF: {
        title: 'Written-off',
        color: CssColors.mainGray,
        borderColor: CssColors.mainGray,
        backgroundColor: CssColors.mainGray,
    },
    PROCESSING: {
        title: 'Processing',
        color: CssColors.mainYellow,
        borderColor: CssColors.mainYellow,
        backgroundColor: CssColors.mainYellow,
    },
    ERROR: {
        title: 'Error',
        color: CssColors.mainRed,
        borderColor: CssColors.mainRed,
        backgroundColor: CssColors.mainRed,
    },
    [DEFAULT_STATUS_KEY]: {
        title: '',
        color: CssColors.mainGray,
        borderColor: CssColors.mainGray,
        backgroundColor: CssColors.mainGray,
    },
};

export const getLoanStatuses = () => {
    return convertMapToList(LoanStatusInfoMap);
};
