import { Tooltip } from 'antd';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { EllipsisContainer } from 'src/components/EllipsisContainer/EllipsisContainer';
import {
    MainContainer,
    MainEntityListItemText,
    MasterDetailsAsideItem,
    SecondaryContainer,
    SecondaryEntityListItemText,
} from 'src/components/MasterDetailsPage/MasterDetailsAsideItem';
import { EntityLinks } from 'src/core/router/EntityLinks';
import { TaskRepresentation } from 'src/generated-api-client';
import { DateHelper } from 'src/utils/DateHelper';

type RequestsLogItemProps = { item: TaskRepresentation };

export const RequestsLogListItem = observer(
    ({ item }: RequestsLogItemProps) => {
        const { t } = useTranslation();

        const hasL10nTitleKey = Boolean(item.extensions?.l10nTitleKey);
        const title = hasL10nTitleKey
            ? t(`${item.extensions?.l10nTitleKey}`, {
                  ...item?.taskInfo,
              })
            : item.name;

        return (
            <MasterDetailsAsideItem
                to={EntityLinks.requestsLog.itemDetails(item.id)}
            >
                <MainContainer>
                    <Tooltip title={title}>
                        <EllipsisContainer>
                            <MainEntityListItemText>
                                {title}
                            </MainEntityListItemText>
                        </EllipsisContainer>
                    </Tooltip>
                </MainContainer>
                <SecondaryContainer>
                    <SecondaryEntityListItemText>
                        {DateHelper.format(item.createTime)}
                    </SecondaryEntityListItemText>
                </SecondaryContainer>
            </MasterDetailsAsideItem>
        );
    },
);
