import { List } from 'antd';
import { observer } from 'mobx-react-lite';
import { MasterDetailsAside } from 'src/components/MasterDetailsPage/MasterDetailsAside';
import { MasterDetailsPage } from 'src/components/MasterDetailsPage/MasterDetailsPage';
import { TaskRepresentation } from 'src/generated-api-client';
import { RequestsLogListItem } from 'src/pages/RequestsLog/RequestsLogListItem';
import { RequestsLogView } from 'src/pages/RequestsLog/RequestsLogView';
import { AgentsRequestsLogStore } from 'src/stores/AgentsRequestsLogStore/AgentsRequestsLogStore';

const store = AgentsRequestsLogStore;

export const RequestsLogPage = observer(() => {
    return (
        <MasterDetailsPage
            pageName="request-logs"
            store={store}
            asidePanel={<RequestsLogAsidePanel />}
        >
            <RequestsLogView store={store} />
        </MasterDetailsPage>
    );
});

const RequestsLogAsidePanel = observer(() => {
    return (
        <MasterDetailsAside
            store={store}
            renderItem={(item: TaskRepresentation) => {
                return (
                    <List.Item key={item.id}>
                        <RequestsLogListItem item={item} />
                    </List.Item>
                );
            }}
        />
    );
});
