export const CssVariables = {
    headerHeight: '3.5rem',

    masterDetailsAsideWidth: '31rem',
};

export const CssColors = {
    black: '#1d1d1f',
    mainGreen: '#3FE579',
    mainYellow: '#E5DF3F',
    secondaryGreen: '#9CE4A3',
    mainRed: '#F33F4A',
    mainGray: '#9B9BA4',
    inputBorderColor: '#D2D2D7',
};
