import { NavLink, NavLinkProps } from 'react-router-dom';
import styled from 'styled-components';

export const MasterDetailsAsideItem = (
    props: React.PropsWithoutRef<NavLinkProps<any>> &
        React.RefAttributes<HTMLAnchorElement>,
) => {
    return (
        <StyledNavLink
            {...props}
            onClick={(e) => {
                if (
                    (e.currentTarget as HTMLElement).classList.contains(
                        'active',
                    )
                ) {
                    e.preventDefault();
                }
            }}
        />
    );
};

export const StyledNavLink = styled(NavLink)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.8rem 1.5rem;
    background: var(--aside-list-item-bg);
    font-size: 1.7rem;
    color: var(--form-text-color);
    border: 0.08rem solid #d2d2d7;
    border-radius: 0.7rem;
    margin: 0.7rem 0;
    min-height: 7.5rem;

    .strong-text {
        font-weight: 600;
    }

    &:hover {
        border-color: var(--aside-list-border-color-hover);
        color: var(--form-text-color);
        text-decoration: none;
    }

    &.active {
        border-color: var(--aside-list-active-border-color);
        background: var(--aside-list-active-bg);
        border-width: 0.14rem;
    }
`;

export const MainContainer = styled.div`
    margin: 0.2rem 0;
`;

export const SecondaryContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    height: 100%;
    max-width: 50%;
    overflow: hidden;
`;

export const MainEntityListItemText = styled.span`
    font-size: 1.2rem;
    line-height: 125%;
    letter-spacing: -0.3px;
    text-transform: capitalize;
    color: var(--form-text-color);
`;

export const SecondaryEntityListItemText = styled.span`
    font-size: 0.9rem;
    line-height: 120%;
    letter-spacing: -0.1px;
    color: var(--form-secondary-text-color);
    margin-bottom: 0.2rem;
    text-align: right;
`;
