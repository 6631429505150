/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useEffect } from 'react';
import { Env } from 'src/core/Environment';
import { AgentProfileStore } from 'src/stores/AgentProfileStore/AgentProfileStore';
import { AuthStore } from 'src/stores/AuthStore/AuthStore';
import { UserStore } from 'src/stores/UserStore/UserStore';

export const useSegmentIdentify = () => {
    const { userProfile } = UserStore;
    const agentProfile = AgentProfileStore?.itemLoader?.data;

    useEffect(() => {
        if (
            AuthStore.authenticated &&
            userProfile?.email &&
            agentProfile?.legalName &&
            Env.isAgentPortal
        ) {
            // @ts-ignore
            window?.analytics?.identify(userProfile?.username, {
                email: userProfile?.email,
                name: `${userProfile?.email} (${agentProfile?.legalName})`,
            });
        }
    }, [AuthStore.authenticated, agentProfile?.legalName, userProfile?.email]);
};
