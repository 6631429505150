export type Action = 'view' | 'manage';

export enum UserRole {
    Verifier = 'Verifier',
    Underwriter = 'Underwriter',
    CreditOfficer = 'CreditOfficer',
    CollectionManager = 'CollectionManager',
    Agent = 'Agent',
    PartnerManager = 'PartnerManager',
}

export type AppFeature =
    | 'tasks'
    | 'applications'
    | 'agentLoanApplications'
    | 'loans'
    | 'agentLoans'
    | 'borrowers'
    | 'products'
    | 'campaigns'
    | 'transactions'
    | 'collections'
    | 'agents'
    | 'leads'
    | 'profile'
    | 'operations'
    | 'operationsAgent'
    | 'requestsLog';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export type EntityConfig = Partial<Record<Action, UserRole[]>>;
export type ReadablePermissions = Record<Action, Record<AppFeature, boolean>>;
