const NUMBER_LOCALE = 'en-us';
const formatToUsd = (value: number, fraction = 2) => {
    return Intl.NumberFormat(NUMBER_LOCALE, {
        minimumFractionDigits: fraction,
        currency: 'USD',
        style: 'currency',
    }).format(value);
};

const formatToPercent = (value: number, minimumFractionDigits = 2) => {
    return Number(value / 100).toLocaleString(NUMBER_LOCALE, {
        style: 'percent',
        minimumFractionDigits,
    });
};

const currencyFormatter = (
    value: number,
    fraction = 2,
    currencyMnemonic: string,
    locale: string = NUMBER_LOCALE,
) => {
    return `$${Intl.NumberFormat(getLocaleForNumberFormat(locale), {
        minimumFractionDigits: fraction,
        currency: currencyMnemonic,
    }).format(value)}`;
};

const getSeparatorsByLocale = (
    locale: string,
): {
    thousands: string;
    decimal: string;
} => {
    const numberFormat = new Intl.NumberFormat(
        getLocaleForNumberFormat(locale),
    );

    return {
        thousands: numberFormat.format(1111).replace(/1/g, ''),
        decimal: numberFormat.format(1.1).replace(/1/g, ''),
    };
};

const getLocaleForNumberFormat = (locale: string) => {
    return locale === 'es' ? 'fr' : locale;
};

export const NumberHelper = {
    formatToUsd,
    formatToPercent,
    currencyFormatter,
    getSeparatorsByLocale,
};
